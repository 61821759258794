<template>
  <div>
    <div class="d-flex flex-column">
      <!-- <ThePageTitle>Request for payment</ThePageTitle> -->

      <div v-if="$store.state.paymentDetails.Request.TotalLeftToPay >= 0">
        <div
          v-if="!$store.state.paymentDetails.IsGenericLink"
          class="
            payment-title
            mt-1
            mb-n2
            py-2
            d-flex
            justify-space-between
            align-center
          "
        >
          <p tabindex="0" class="my-0 grey--text">
            Payment due on following items:
          </p>
          <v-btn
            @click.stop="dialog = true"
            aria-label="Help"
            class="mr-n2"
            fab
            text
            color="grey lighten-2"
            depressed
            small
          >
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </div>

        <!-- Payment breakdown  -->
        <v-card flat class="payment-overview mt-1 grey lighten-3 rounded-md">
          <v-card-text
            class="text-body-1"
            :class="$store.state.paymentDetails.Property ? 'pt-3' : 'pt-8'"
          >
            <div
              v-if="$store.state.paymentDetails.Property"
              class="text-h6 text-center mt-0 mb-5"
            >
              <span
                class="d-inline-block text-truncate"
                style="max-width: 300px"
              >
                {{ $store.state.paymentDetails.Property }}
              </span>
            </div>

            <div v-if="!$store.state.paymentDetails.IsGenericLink">
              <v-row
                v-for="payment in duePayments"
                :key="payment.id"
                align="center"
                class="mb-6"
              >
                <v-col class="py-0">
                  <div class="d-flex align-center">
                    <p
                      tabindex="0"
                      :aria-label="`Payment description: ${payment.Description}`"
                      class="my-0 mr-1"
                    >
                      {{ payment.Description }}
                    </p>
                    <v-icon
                      small
                      v-if="individualPaymentDiffers(payment) === ''"
                      color="green"
                      >mdi-check</v-icon
                    >
                  </div>
                  <p class="text-caption my-0 primary--text">
                    {{ individualPaymentDiffers(payment) }}
                  </p>
                </v-col>
                <v-col class="py-0" cols="6" sm="4">
                  <v-text-field
                    v-model.number="payment.userInputAmount"
                    aria-label="Payment amount"
                    type="number"
                    outlined
                    dense
                    reverse
                    class="mb-n6 rounded-sm"
                    append-icon="mdi-pencil-outline"
                    hint="Edit the amount"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider class="my-5"></v-divider>

              <v-row align="center">
                <v-col class="">
                  <p tabindex="0" class="my-0 font-weight-black">
                    Total amount
                  </p>
                  <p class="text-caption primary--text my-0">
                    {{ totalPaymentDifference }}
                  </p>
                </v-col>
                <v-col class="pr-6 pr-lg-9" cols="6" sm="4">
                  <div class="d-flex justify-space-between font-weight-black">
                    <p
                      tabindex="0"
                      aria-label="Great british pounds"
                      class="my-0"
                    >
                      GBP
                    </p>
                    <p tabindex="0" class="my-0">
                      {{ calcTotalPaymentAmount | formatAmount }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div v-if="$store.state.paymentDetails.IsGenericLink">
              <p class="font-weight-light text-center mb-8">
                In order for your agent to easily identify you for this payment,
                please enter your full name and email address.
              </p>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row align="center" class="mb-6">
                  <v-col cols="12" class="">
                    <v-text-field
                      required
                      class="rounded-sm"
                      v-model="genericLinkFullName"
                      aria-label="User full name"
                      outlined
                      dense
                      append-icon="mdi-account"
                      placeholder="Enter your name and surname"
                      label="Full name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="">
                    <v-text-field
                      required
                      v-model="genericLinkEmail"
                      aria-label="User email"
                      outlined
                      dense
                      placeholder="Enter your email address"
                      class="mt-n6 rounded-sm"
                      :rules="emailRules"
                      append-icon="mdi-at"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="9">
                    <div class="d-flex align-center">
                      <v-text-field
                        hint="E.g.: Rent"
                        persistent-hint
                        v-model="genericLinkDescription"
                        aria-label="Payment amount"
                        outlined
                        dense
                        placeholder="Payment description"
                        class="rounded-sm"
                        append-icon="mdi-help-circle"
                        @click:append="dialog = true"
                        :rules="descriptionRules"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    class="py-0 mt-8 mt-sm-0"
                    cols="7"
                    offset="5"
                    sm="3"
                    offset-sm="0"
                  >
                    <v-text-field
                      v-model.number="genericLinkAmount"
                      aria-label="Payment amount"
                      type="number"
                      outlined
                      dense
                      class="rounded-sm"
                      placeholder="Amount"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider class="my-5"></v-divider>
              <div class="d-flex align-center font-weight-black">
                <p tabindex="0" class="my-0">Total amount</p>
                <v-spacer></v-spacer>
                <p tabindex="0" class="my-0">{{ formatGenericLinkAmount }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <!-- /Payment breakdown  -->

        <div class="payment-method-title mt-8 mb-3">
          <p tabindex="0" class="my-0 text-center text-uppercase">
            select payment method
          </p>
        </div>

        <!-- Payment methods -->
        <v-card
          v-for="method in unfilteredPaymentMethods"
          :key="method.id"
          @click="setPaymentMethod(method)"
          flat
          class="mb-3 rounded-md"
          :class="[
            $store.state.oneOffPaymentMethod === method.enum ? 'secondary' : '',
            (method.show === false ? 'hide-input' : '')
           ]
          "
          outlined
        >
          <v-card-text
            class="py-3 d-flex justify-space-between"
            :class="
              $store.state.oneOffPaymentMethod === method.enum
                ? 'white--text'
                : ''
            "
          >
            <div>
              <p class="my-0 text-body-1">{{ method.title }}</p>
              <p class="my-0 text-caption font-weight-thin">
                {{ method.subtitle }}
              </p>
            </div>
            <v-icon
              v-if="$store.state.oneOffPaymentMethod === method.enum"
              color="white"
              >mdi-check-circle-outline</v-icon
            >
            <v-icon v-else color="grey">mdi-circle-outline</v-icon>
          </v-card-text>
        </v-card>
        <!-- /Payment methods card -->

        <v-btn
          @click="setDetailAndContinue"
          :disabled="!validationOK"
          class="mb-3 mt-5"
          x-large
          depressed
          color="primary"
          block
        >
          continue
        </v-btn>
      </div>

      <div v-else class="text-center">
        <p class="text-h6 font-weight-black mt-12">
          No outstanding payments present.
        </p>
        <v-icon class="icon" color="grey lighten-4" size="150"
          >mdi-file-check-outline</v-icon
        >
      </div>

      <v-dialog
        v-model="dialog"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="primary py-2">
            <span class="white--text">Payments</span>
            <v-spacer></v-spacer>
            <v-btn small fab text @click="dialog = false" color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pt-3">
            <div v-if="$store.state.paymentDetails.IsGenericLink">
              <p class="mb-0 font-weight-bold">User details</p>
              <v-divider class="mb-3"></v-divider>
              <p>
                In order for your agent to easily identify you for this payment,
                please enter your full name and email address. <br />
              </p>
              <p class="mb-0 font-weight-bold">Payment description</p>
              <v-divider class="mb-3"></v-divider>
              <p>
                Enter a short descritpion of the payment. (e.g: rent, deposit,
                holding fee)
              </p>
              <p class="mb-0 font-weight-bold">Amount</p>
              <v-divider class="mb-3"></v-divider>
              <p>Enter the amount you wish to pay in pounds (GBP/£).</p>
              <p class="mb-0 font-weight-bold">Payment method</p>
              <v-divider class="mb-3"></v-divider>
              <p>
                Select how to pay by choosing
                <strong>Secure bank transfer</strong> or
                <strong>Pay by card</strong>.
              </p>
            </div>
            <div v-else>
              <p class="mb-0 font-weight-bold">Editing payment details</p>
              <v-divider class="mb-3"></v-divider>
              <p>
                You can change the amount for individual payments to your
                liking.
              </p>
              <p>
                Amounts due will still be visble when accessing this application
                in the future.
              </p>
              <p class="mb-0 font-weight-bold">Payment method</p>
              <v-divider class="mb-3"></v-divider>
              <p>
                <strong>Easy Bank Transfer</strong> (reccomended) allows you to
                complete a secure payment in the safety of your bank`s own
                application.
              </p>
              <p>
                <strong>Pay with card</strong> allows you to complete a quick
                card payment via Barclaycard`s ePDQ eCommerce platform.
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentDetails",
  created() {
    console.log(this.$store.state.cardOnly);
    if(this.$store.state.cardOnly)
      this.selectedPaymentMethod = this.unfilteredPaymentMethods[1]; // Default card
    else{
      this.selectedPaymentMethod = this.unfilteredPaymentMethods[0]; // Default bank
    } // default to first entry in array:-
    this.setPaymentMethod(this.selectedPaymentMethod) // Select default
    const originalPayments = this.$store.state.paymentDetails.Request.Breakdown;
    this.duePayments = originalPayments.map((item) => {
      return {
        ...item,
        userInputAmount: item.AmountLeftToPay,
      };
    });
  },
  data() {
    return {
      dialog: false,
      unfilteredPaymentMethods:  [
        {
          id: 1,
          title: "Easy bank transfer",
          subtitle: "Recommended",
          enum: "bankTransfer",
          show: !this.$store.state.cardOnly
        },
        {
          id: 2,
          title: "Pay by card",
          subtitle: "Enter your card details",
          // subtitle: 'Coming soon...',
          enum: "cardPayment",
          show: this.$store.state.cardPaymentPermissions.CardPaymentsAllowed
        },
      ],
      totalPaymentAmount: "",
      duePayments: "",
      originalDuePayments: "",
      // Generic link
      genericLinkFullName: "",
      genericLinkEmail: "",
      genericLinkDescription: "",
      genericLinkAmount: null,
      valid: true,
      requiredRules: [
        (v) => !!v || "Amount is required",
        (v) => v > 0 || "Min amount £1",
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 5) || "Name must be at least 5 characters",
      ],
      descriptionRules: [
        (v) => !!v || "Description is required",
        (v) =>
          (v && v.length >= 3) || "Description must be at least 3 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    validateGenericForm() {
      this.$refs.form.validate();
    },
    individualPaymentDiffers(payment) {
      const difference = Math.abs(
        payment.AmountLeftToPay - payment.userInputAmount
      ).toFixed(2);
      if (payment.AmountLeftToPay > payment.userInputAmount)
        return `£${difference} still due.`;
      if (payment.AmountLeftToPay < payment.userInputAmount)
        return `You entered £${difference} too much.`;
      return "";
    },
    setPaymentMethod(method) {
      this.$store.commit("SET_ONE_OFF_PAYMENT_METHOD", method.enum); // default is hardcoded to: 'bankTransfer'
    },
    setDetailAndContinue() {
      let newPaymentDetails = {
        breakDown: null,
        total: null,
      };
      if (this.$store.state.paymentDetails.IsGenericLink) {
        const formValidated = this.$refs.form.validate();

        if (formValidated) {
          newPaymentDetails.breakDown = [
            {
              Amount: this.genericLinkAmount,
              UserInputtedReference: `${this.genericLinkFullName} \n ${this.genericLinkEmail} \n ${this.genericLinkDescription}`,
            },
          ];
          newPaymentDetails.total = this.genericLinkAmount;
          console.log(newPaymentDetails.total);
          this.$store.commit(
            "SET_PAYMENT_DETAILS_TO_SUBMIT",
            newPaymentDetails
          );

          if (this.$store.state.oneOffPaymentMethod === "bankTransfer")
            this.$router.push({ name: "PaymentSelectBank" });
          else {
            this.$router.push({ name: "PaymentCardConfirm" });
          }
        } else {
          console.log("Form not valid");
        }
      } else {
        (newPaymentDetails.breakDown = this.duePayments),
          (newPaymentDetails.total = this.calcTotalPaymentAmount);
        this.$store.commit("SET_PAYMENT_DETAILS_TO_SUBMIT", newPaymentDetails);
        console.log(newPaymentDetails.total);
        if (this.$store.state.oneOffPaymentMethod === "bankTransfer")
          this.$router.push({ name: "PaymentSelectBank" });
        else {
          this.$router.push({ name: "PaymentCardConfirm" });
        }
      }
    },
  },
  computed: {
    paymentMethods(){ 
      var newArray = this.unfilteredPaymentMethods.filter((currentValue) => {return currentValue.show === true});
      return newArray;
    },
    calcTotalPaymentAmount() {
      const paymentAmounts = this.duePayments.map(
        (item) => item.userInputAmount
      );
      const total = paymentAmounts.reduce((a, b) => a + b, 0).toFixed(2);
      return total;
    },
    calcOriginalTotalPaymentAmount() {
      const paymentAmounts = this.duePayments.map(
        (item) => item.AmountLeftToPay
      );
      const total = paymentAmounts.reduce((a, b) => a + b, 0);
      return total;
    },
    totalPaymentDifference() {
      const difference = Math.abs(
        this.calcOriginalTotalPaymentAmount - this.calcTotalPaymentAmount
      ).toFixed(2);
      if (this.calcOriginalTotalPaymentAmount > this.calcTotalPaymentAmount)
        return `£${difference} still due`;
      if (this.calcOriginalTotalPaymentAmount < this.calcTotalPaymentAmount)
        return `Your total is £${difference} over what is due. Please check your numbers.`;
      return "";
    },
    validationOK() {
      if (this.$store.state.paymentDetails.IsGenericLink) {
        //validation happening after user presses continu button
        return true;
      } else {
        // non generic payment
        if (
          this.calcTotalPaymentAmount > this.calcOriginalTotalPaymentAmount ||
          this.calcTotalPaymentAmount <= 0
        )
          return false;
        return true;
      }
    },
    formatGenericLinkAmount() {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(this.genericLinkAmount);
    },
  },
};
</script>

<style scoped>
.hide-input{
  display: none !important;
}
</style>
